import {CoreMenu} from '@core/types';

export const menu: CoreMenu[] = [
    /*{
        id: 'home',
        title: 'Home',
        translate: 'MENU.HOME',
        type: 'item',
        icon: 'home',
        url: 'home'
    },
    {
        id: 'sample',
        title: 'Sample',
        translate: 'MENU.SAMPLE',
        type: 'item',
        icon: 'file',
        url: 'sample'
    },*/
    {
        id: 'dashboard',
        title: 'Dashboard',
        translate: 'MENU.DASHBOARD',
        type: 'item',
        icon: 'fas fa-sign',
        url: 'dashboard',
        availableTo: ['SUPER_ADMIN', 'ADMIN', 'HEAD_COUNSELLOR', 'COUNSELLOR', 'UNIVERSITY']
    },
    {
        id: 'landing-page',
        title: 'Landing Page',
        translate: 'MENU.LANDING_PAGE',
        type: 'item',
        icon: 'fas fa-seedling',
        url: 'university-landing',
        availableTo: ['UNIVERSITY']
    },
    {
        id: 'admins',
        title: 'Admins',
        translate: 'MENU.ADMINS',
        type: 'item',
        icon: 'fas fa-user-shield',
        url: 'admins',
        availableTo: ['SUPER_ADMIN']
    },
    {
        id: 'head-counsellors',
        title: 'Head Counsellors',
        translate: 'MENU.HEAD_COUNSELLORS',
        type: 'item',
        icon: 'fas fa-user-tie',
        url: 'head-counsellors',
        availableTo: ['SUPER_ADMIN', 'ADMIN']
    },
    {
        id: 'counsellors',
        title: 'Counsellors',
        translate: 'MENU.COUNSELLORS',
        type: 'item',
        icon: 'fas fa-user-tie',
        url: 'counsellors',
        availableTo: ['HEAD_COUNSELLOR']
    },
    {
        id: 'universities',
        title: 'Universities',
        translate: 'MENU.UNIVERSITIES',
        type: 'item',
        icon: 'fas fa-university',
        url: 'universities',
        availableTo: ['SUPER_ADMIN', 'ADMIN']
    },
    {
        id: 'programs',
        title: 'Programs',
        translate: 'MENU.PROGRAMS',
        type: 'item',
        icon: 'fas fa-shekel-sign',
        url: 'programs',
        availableTo: ['UNIVERSITY'],
    },
    {
        id: 'courses',
        title: 'Courses',
        translate: 'MENU.COURSES',
        type: 'item',
        icon: 'fas fa-spa',
        url: 'courses',
        availableTo: ['UNIVERSITY']
    },
    {
        id: 'branches',
        title: 'Branches',
        translate: 'MENU.BRANCHES',
        type: 'item',
        icon: 'fab fa-stumbleupon-circle',
        url: 'branches',
        availableTo: ['UNIVERSITY']
    },
    {
        id: 'pcbmapping',
        title: 'PCB-Mapping',
        translate: 'MENU.PCBMAPPING',
        type: 'item',
        icon: 'fas fa-code-branch',
        url: 'pcb-mapping',
        availableTo: ['UNIVERSITY']
    },
    {
        id: 'stages',
        title: 'Stages',
        translate: 'MENU.STAGES',
        type: 'item',
        icon: 'fab fa-centos',
        url: 'stages',
        availableTo: ['SUPER_ADMIN', 'ADMIN']
    },
    {
        id: 'reasons',
        title: 'Reasons',
        translate: 'MENU.REASONS',
        type: 'item',
        icon: 'fas fa-question',
        url: 'reasons',
        availableTo: ['SUPER_ADMIN', 'ADMIN']
    },
    {
        id: 'srmapping',
        title: 'SR-Mapping',
        translate: 'MENU.SRMAPPING',
        type: 'item',
        icon: 'fas fa-code-branch',
        url: 'sr-mapping',
        availableTo: ['SUPER_ADMIN', 'ADMIN']
    },
    {
        id: 'students',
        title: 'Students',
        translate: 'MENU.STUDENTS',
        type: 'item',
        icon: 'fas fa-user-graduate',
        url: 'students',
        availableTo: ['SUPER_ADMIN', 'ADMIN', 'HEAD_COUNSELLOR', 'COUNSELLOR', 'UNIVERSITY']
    },
    {
        id: 'universitysettings',
        title: 'Settings',
        translate: 'MENU.UNIVERSITYSETTINGS',
        type: 'item',
        icon: 'fas fa-sliders-h',
        url: 'universitysettings',
        availableTo: ['UNIVERSITY']
    },
    /*{
        id: 'mails',
        title: 'Mails',
        translate: 'MENU.MAILS',
        type: 'item',
        icon: 'far fa-envelope',
        url: 'mails',
        availableTo: ['SUPER_ADMIN', 'ADMIN', 'COUNSELLOR']
    },
    {
        id: 'text_messages',
        title: 'Text Messages',
        translate: 'MENU.TEXT_MESSAGES',
        type: 'item',
        icon: 'fab fa-buromobelexperte',
        url: 'text-messages',
        availableTo: ['SUPER_ADMIN', 'ADMIN', 'COUNSELLOR']
    },
    {
        id: 'whats_apps',
        title: 'Whatsapps',
        translate: 'MENU.WHATSAPP',
        type: 'item',
        icon: 'fab fa-whatsapp',
        url: 'whatsapps',
        availableTo: ['SUPER_ADMIN', 'ADMIN', 'COUNSELLOR']
    }*/
];
